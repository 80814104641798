import * as React from 'preact'
import RotatingProjects from '../../components/RotatingProjects'

// eslint-disable-next-line no-undef
const projectsData = require('../../data/projects.json')

export default class HomepageProjects extends React.Component<{}, {}> {
    public render() {
        return (
            <div id="projects" className="section homepage-section homepage-projects">
                <div className="container">
                    <div className="section__title">
                        <div className="section__title__index">02</div>
                        <h2 className="section__title__text">Recent Work</h2>
                    </div>
                    <div className="project__list">
                        <RotatingProjects type="WeChat Mini Programs" projects={projectsData.wechat} />
                        <RotatingProjects type="Shopify / E-Commerce" projects={projectsData.shopify} />
                    </div>
                </div>
            </div>
        )
    }
}
