import * as React from 'preact'
import * as typeformEmbed from '@typeform/embed'

interface TypeFormProps {
    formLink: string
    onSubmit: any
}

export default class TypeForm extends React.Component<TypeFormProps, {}> {
    private el: any

    public constructor(props: TypeFormProps) {
        super(props)

        this.el = null
    }

    public componentDidMount() {
        const { formLink } = this.props

        if (this.el) {
            typeformEmbed.makeWidget(this.el, formLink, {
                hideFooter: true,
                hideHeaders: true,
                opacity: 0,
                onSubmit: () => {
                    console.log('Typeform successfully submitted')
                    this.props.onSubmit()
                }
            })
        }
    }

    public render() {
        return (
            <div className="typeform-wrapper">
                <div ref={el => (this.el = el)} className="typeform-form" />
            </div>
        )
    }
}
