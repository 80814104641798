import * as React from 'preact'
import Link from './Link'

const Footer: React.FunctionalComponent = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__inner">
                    <div className="footer__navigation">
                        <div className="footer__navigation__list">
                            <Link href="/#services" className="footer__navigation__item" data-index="01">
                                Services
                            </Link>
                            <Link href="/#projects" className="footer__navigation__item" data-index="02">
                                Projects
                            </Link>
                            <Link href="/#our-clients" className="footer__navigation__item" data-index="03">
                                Our Clients
                            </Link>
                            <Link href="/#about-us" className="footer__navigation__item" data-index="04">
                                About Us
                            </Link>
                        </div>
                    </div>
                    <div className="footer__contact">
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <div className="footer__contact__title">Let's Collaborate!</div>
                        <div className="footer__contact__inner">
                            <div className="footer__contact__email">
                                <div className="footer__contact__email__title">Say Hello</div>
                                <div className="footer__contact__email__text">
                                    <a href="mailto:hello@eskaytech.com">hello@eskaytech.com</a>
                                </div>
                                <div className="footer__contact__email__submit">
                                    <Link href="/contact-us">
                                        <button className="button button-cta button--gradient">
                                            Submit An Enquiry
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="footer__contact__company">
                                <ul className="footer__contact__company__menu">
                                    <li>
                                        <a
                                            href="https://www.linkedin.com/company/eskay-tech/jobs"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            Careers
                                        </a>
                                    </li>
                                    <li>
                                        <Link href="/privacy-policy">Privacy Policy</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__copyright">
                    <div className="footer__copyright__logo">
                        <img src="/assets/images/logo-white.png" alt="Eskay Tech Logo" />
                    </div>
                    <div className="footer__copyright__text">&copy; 2018 - 2023 | Eskay Tech Co,. Ltd.</div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
