import * as React from 'preact'

export default class HomepageLocations extends React.Component<{}, {}> {
    private static renderLocation(image: string, title: string, address: string, tel?: string) {
        return (
            <div className="location__item location__item--primary">
                <div className="location__item__image">
                    <div style={{ backgroundImage: `url(${image})` }} />
                </div>

                <div className="location__item__content">
                    <div className="location__item__content__title">{title}</div>
                    <div className="location__item__content__address">{address}</div>
                    {
                        !!tel && (
                            <div className="location__item__content__contact">
                                <a href={`tel:${tel}`}>{tel}</a>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

    public render() {
        return (
            <div id="about-us" className="section homepage-section homepage-locations">
                <div className="container">
                    <div className="section__title">
                        <div className="section__title__index">04</div>
                        <h2 className="section__title__text">A little about us</h2>
                    </div>
                    <div className="section__content">
                        <p>
                            We are a small yet effective team of 7, based in Asia.
                            <br />
                            <a
                                className="highlight"
                                href="https://www.linkedin.com/company/eskay-tech/people"
                                rel="noreferrer"
                                target="_blank"
                            >
                                Our team&nbsp;
                            </a>
                            is guided by a relentless approach on quality, rapid development and minimalism.
                        </p>
                    </div>
                    <div className="location__list">
                        {HomepageLocations.renderLocation(
                            '/assets/images/location-sh.jpg',
                            'Shanghai',
                            '17F, Tower B, 838 S. Huangpi road Huangpu District, Shanghai, 200120'
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
