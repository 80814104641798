import * as React from 'preact'
import { route } from 'preact-router'
import { Link as RouterLink } from 'preact-router/match'

interface LinkProps {
    href: string
    className?: string
}

class Link extends React.Component<LinkProps, {}> {
    public render() {
        const { ...rest } = this.props
        return <RouterLink activeClassName="active" {...rest} onClick={this.goTo.bind(this)} />
    }

    private goTo(e: any) {
        e.preventDefault()
        const isHash = this.props.href.indexOf('#') !== -1

        if (!isHash) {
            route(this.props.href)
            setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 10)
        } else {
            const url = this.props.href.split('#')[0]
            const hash = this.props.href.split('#')[1]
            route(url)

            setTimeout(() => {
                const section = document.querySelector(`#${hash}`)

                if (section) {
                    section.scrollIntoView({ behavior: 'smooth' })
                }
            }, 100)
        }
    }
}

export default Link
