import * as React from 'preact'
import TypeForm from '../../components/Typeform'

interface ContactFormState {
    isSubmitted: boolean
}

export default class ContactForm extends React.Component<{}, ContactFormState> {
    constructor(props: any) {
        super(props)

        this.state = {
            isSubmitted: false
        }
    }

    public render() {
        const { isSubmitted } = this.state

        return (
            <div className="section contact-section contact-form">
                <div className="container">
                    <div className="contact-form__inner">
                        <h1 className="contact-form__title">Contact Us</h1>
                        <div className="contact-form__content">
                            <p>
                                Get in touch with us for all your staffing requirements and hire our optimal services
                                for getting the project developments done with your budget estimate.
                            </p>
                        </div>
                    </div>
                </div>

                {!isSubmitted && (
                    <div className="container">
                        <div className="contact-form__form">
                            <TypeForm
                                formLink="https://form.typeform.com/to/TuaRHoHN"
                                onSubmit={() => {
                                    console.log('hide the form now !')
                                    this.setState({ isSubmitted: true })
                                }}
                            />
                        </div>
                    </div>
                )}

                {isSubmitted && (
                    <div className="container">
                        <div className="contact-form__form-submit">Form is submitted!</div>
                    </div>
                )}
            </div>
        )
    }
}
