import { RoutableProps, RouteProps } from 'preact-router'
import Homepage from '../routes/Homepage/Homepage'
import Contact from '../routes/Contact/Contact'
import Privacy from "../routes/Privacy/Privacy";

export enum RouteExposeType {
    REGULAR = 'regular',
    PRIVATE = 'private',
    PUBLIC = 'public'
}

export interface RouteConfig extends RouteProps<RoutableProps> {
    titleKey?: string
    exposeType?: RouteExposeType
}

export const routes = [
    {
        path: '/',
        component: Homepage,
        titleKey: 'homepage',
        exposeType: RouteExposeType.REGULAR,
        exact: true
    },
    {
        path: '/contact-us',
        component: Contact,
        titleKey: 'contact',
        exposeType: RouteExposeType.REGULAR,
        exact: true
    },
    {
        path: '/privacy-policy',
        component: Privacy,
        titleKey: 'privacy',
        exposeType: RouteExposeType.REGULAR,
        exact: true
    }
]
