import * as React from 'preact'

export default class HomepageIntro extends React.Component<{}, {}> {
    public render() {
        return (
            <div className="section homepage-section homepage-intro">
                <div className="container">
                    <div className="homepage-intro__inner">
                        <h1 className="homepage-intro__title">
                            We build <span className="highlight">scalable</span> solutions to real world problems.
                        </h1>
                        <div className="homepage-intro__content">
                            <p>
                                From marketing solutions, enterprise software to SaaS platforms, we create digital
                                experiences that users love.
                            </p>
                        </div>
                        <div className="homepage-intro__metrics">
                            <div className="homepage-intro__metric">
                                <div className="homepage-intro__metric__value">20+</div>
                                <div className="homepage-intro__metric__label">
                                    Successful <br />
                                    Projects
                                </div>
                            </div>
                            <div className="homepage-intro__metric">
                                <div className="homepage-intro__metric__value">12+</div>
                                <div className="homepage-intro__metric__label">
                                    Happy <br />
                                    Clients
                                </div>
                            </div>
                            <div className="homepage-intro__metric">
                                <div className="homepage-intro__metric__value">03</div>
                                <div className="homepage-intro__metric__label">
                                    Dedicated <br />
                                    Teams
                                </div>
                            </div>
                            <div className="homepage-intro__metric">
                                <div className="homepage-intro__metric__value">4</div>
                                <div className="homepage-intro__metric__label">
                                    Years <br />
                                    in Action
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
