import * as React from 'preact'

export default class HomepageServices extends React.Component<{}, {}> {
    public render() {
        return (
            <div id="services" className="section section--highlight homepage-section homepage-services">
                <div className="container">
                    <div className="section__title">
                        <div className="section__title__index">01</div>
                        <h2 className="section__title__text">Our Services</h2>
                    </div>
                    <div className="section__content">
                        <p>
                            We offer a suite of design and development services with talented people on board, <br />
                            who see the bigger picture, understand your business challenges and your customers’ needs.
                        </p>
                    </div>
                    <div className="services__list">
                        <div className="services__item">Web Development</div>
                        <div className="services__item">UX & UI Design</div>
                        <div className="services__item">Mobile APP Development</div>
                        <div className="services__item">Strategy & Discovery</div>
                        <div className="services__item">WeChat Development</div>
                        <div className="services__item">Marketing & SEO</div>
                    </div>
                </div>
            </div>
        )
    }
}
