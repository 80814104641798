import * as React from 'preact'
import { RouteConfig } from '../config/router.config'

class Route extends React.Component<RouteConfig, {}> {
    constructor(props: RouteConfig) {
        super(props)
    }

    public render() {
        const { component: Component, ...rest } = this.props

        return <Component {...rest} />
    }
}

export default Route
