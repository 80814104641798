import * as React from 'preact'
import Page from '../../containers/Page'
import HomepageIntro from './HomepageIntro'
import HomepageServices from './HomepageServices'
import HomepageClients from './HomepageClients'
import HomepageProjects from './HomepageProjects'
import HomepageLocations from './HomepageLocations'
import ContactCTA from '../../components/ContactCTA'

export default class Home extends React.Component<{}, {}> {
    public componentDidMount() {}

    public render() {
        return (
            <Page type="homepage">
                <HomepageIntro />
                <HomepageServices />
                <HomepageProjects />
                <HomepageClients />
                <ContactCTA />
                <HomepageLocations />
            </Page>
        )
    }
}
