import * as React from 'preact'
import Page from '../../containers/Page'
// eslint-disable-next-line no-undef
const privacyHtml = require('../../privacy-policy.html').default

const Privacy: React.FunctionalComponent = () => {
    return (
        <Page type="privacy">
            <div className="container">
                <div dangerouslySetInnerHTML={{ __html: privacyHtml }} />
            </div>
        </Page>
    )
}

export default Privacy
