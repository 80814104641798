import * as React from 'preact'

interface RotatingProjectsProps {
    type: string
    projects: any[]
}

interface RotatingProjectsState {
    currentIndex: number
}

export default class RotatingProjects extends React.Component<RotatingProjectsProps, RotatingProjectsState> {
    private interval: any

    constructor(props: RotatingProjectsProps) {
        super(props)

        this.state = {
            currentIndex: 0
        }
    }

    public componentDidMount() {
        const { projects } = this.props

        this.interval = setInterval(() => {
            const currentIndex = this.state.currentIndex >= projects.length - 1 ? 0 : this.state.currentIndex + 1
            this.setState({ currentIndex })
        }, 5000)
    }

    public render() {
        const { type, projects } = this.props
        const { currentIndex } = this.state

        return (
            <div className="rotating-projects">
                {projects.map((p, i) => (
                    <div
                        key={`${type}-${i}`}
                        className={`project__item ${currentIndex === i ? 'show' : ''}`}
                        style={{ backgroundImage: `url(${p.bg})` }}
                    >
                        <div className="project__item__content">
                            <div className="project__item__title">{type}</div>
                        </div>
                    </div>
                ))}

                <div className="rotating-projects__pagination">
                    {projects.map((p, i) => (
                        <div
                            key={`${type}-${i}`}
                            className={`rotating-projects__pagination-item ${currentIndex === i ? 'active' : ''}`}
                        />
                    ))}
                </div>
            </div>
        )
    }
}
