import * as React from 'preact'
// import Splide from '@splidejs/splide'

export default class HomepageClients extends React.Component<{}, {}> {
    private slider: any
    private splide: any

    static renderLogo(logo: string) {
        return (
            <div className="brand__item">
                <img src={logo} />
            </div>
        )
    }

    static renderQuote(quote: string) {
        return (
            <div className="brand__quote splide__slide">
                <div className="brand__quote__text">{quote}</div>
                <div className="brand__quote__cite">
                    <div className="brand__quote__cite__image">
                        <img src="/assets/images/user.jpeg" alt="User Name" />
                    </div>
                    <div className="brand__quote__cite__name__wrap">
                        <div className="brand__quote__cite__name">Udit Veerwani</div>
                        <div className="brand__quote__cite__caption">Director @ Eskay Tech</div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        // this.splide = new Splide('.splide', {
        //     type: 'loop',
        //     perPage: 1,
        //     autoHeight: true
        // }).mount()
    }

    componentWillUnmount() {
        // this.splide.destroy()
    }

    public render() {
        return (
            <div id="our-clients" className="section homepage-section homepage-clients">
                <div className="container">
                    <div className="section__title">
                        <div className="section__title__index">03</div>
                        <h2 className="section__title__text">We work with</h2>
                    </div>

                    <div className="brand__list">
                        {HomepageClients.renderLogo('/assets/images/logo-alpha-partners.png')}
                        {HomepageClients.renderLogo('/assets/images/logo-bevplus.png')}
                        {HomepageClients.renderLogo('/assets/images/logo-coravin.png')}
                        {HomepageClients.renderLogo('/assets/images/logo-taeltech.png')}
                        {HomepageClients.renderLogo('/assets/images/logo-travis.png')}
                        {HomepageClients.renderLogo('/assets/images/logo-hbb.png')}
                        {HomepageClients.renderLogo('/assets/images/logo-kaiterra.png')}
                    </div>

                    {/*<div*/}
                    {/*    className="brand__quotes splide"*/}
                    {/*    ref={ref => {*/}
                    {/*        this.slider = ref*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <div className="splide__track">*/}
                    {/*        <ul className="splide__list">*/}
                    {/*            {HomepageClients.renderQuote(*/}
                    {/*                'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor!'*/}
                    {/*            )}*/}
                    {/*            {HomepageClients.renderQuote(*/}
                    {/*                'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'*/}
                    {/*            )}*/}
                    {/*            {HomepageClients.renderQuote(*/}
                    {/*                'We call Q guys ‘Croatian Ninjas’ for their speed and efficiency.'*/}
                    {/*            )}*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}
