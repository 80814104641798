import * as React from 'preact'
import Page from '../../containers/Page'
import ContactForm from './ContactForm'

const Contact: React.FunctionalComponent = () => {
    return (
        <Page type="contact">
            <ContactForm />
        </Page>
    )
}

export default Contact
