import * as React from 'preact'
import Link from './Link'

const Header: React.FunctionalComponent = () => {
    return (
        <header className="header">
            <div className="container">
                <div className="header__inner">
                    <div className="header__logo">
                        <Link href="/">
                            <img src="/assets/images/logo.png" alt="Eskay Tech Logo" />
                        </Link>
                    </div>
                    <nav className="header__navigation">
                        <Link href="/#services">
                            <span>Services</span>
                        </Link>
                        <Link href="/#projects">
                            <span>Projects</span>
                        </Link>
                        <Link href="/#about-us">
                            <span>About Us</span>
                        </Link>
                        <Link href="/contact-us">
                            <button className="button button-cta button--gradient">Contact Us</button>
                        </Link>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default Header
