import * as React from 'preact'

interface PageProps {
    type: string
}

class Page extends React.Component<PageProps, {}> {
    public render() {
        const { type } = this.props

        return (
            <div className={`page page-${type}`}>
                <div className="page__inner">{this.props.children}</div>
            </div>
        )
    }
}

export default Page
