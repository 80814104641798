import * as React from 'preact'
import Router from 'preact-router'
import { Provider } from 'unistore/preact'

import store from '../store'
import { RouteConfig, RouteExposeType, routes } from '../config/router.config'

import NotFound from '../routes/NotFound'
import Route from '../components/Route'
import Header from '../components/Header'
import Footer from '../components/Footer'

interface RootProps {}

class Root extends React.Component<RootProps, {}> {
    public render() {
        return (
            <Provider store={store}>
                <div>
                    <Header />
                    <Router>
                        {routes.map((route: RouteConfig) => {
                            const key = route.titleKey || Math.ceil(Math.random() * 1000)
                            if (route.exposeType === RouteExposeType.PRIVATE) {
                                return <Route key={key} {...route} />
                            } else if (route.exposeType === RouteExposeType.PUBLIC) {
                                return <Route key={key} {...route} />
                            } else {
                                return <Route key={key} {...route} />
                            }
                        })}
                        <NotFound default />
                    </Router>
                    <Footer />
                </div>
            </Provider>
        )
    }
}

export default Root
