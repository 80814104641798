import * as React from 'preact'
import Link from './Link'

export default class ContactCTA extends React.Component<{}, {}> {
    public render() {
        return (
            <div className="section section--contact-cta">
                <div className="container">
                    <div className="contact-cta">
                        <div className="contact-cta__title">Have a project, or want to say hello?</div>
                        <div className="contact-cta__cta">
                            <Link href="/contact-us">
                                <button className="button button-cta button--white">Get in touch</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
